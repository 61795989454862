import { configureStore } from "@reduxjs/toolkit";

import screenerReducer from "./features/screenerSlice";

const dispatchAndPersist = (store) => (next) => (action) => {
  const result = next(action);
  const newState = store.getState();
  if (
    ["screener/answerQuestion", "screener/back", "screener/answerQuestion", "screener/setCurrentStep"].includes(
      action?.type,
    )
  ) {
    localStorage?.setItem("screener", JSON.stringify(newState.screener));
  }
  return result;
};

const hydrateScreener = () => {
  if (typeof window === "undefined") {
    return undefined;
  }
  const storedValue = localStorage?.getItem("screener");
  return { screener: storedValue ? JSON.parse(storedValue) : undefined };
};
const initialState = hydrateScreener();

export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(dispatchAndPersist),
  preloadedState: initialState,
  reducer: { screener: screenerReducer },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
