const contentPage = ["finance/index", "boilers/index", "list/index", "home-page/index", "solar/savings-calculator"];
const guidePage = ["guide-category/index", "guides/index"];

module.exports = {
  defaultLocale: "en",
  locales: ["en"],
  pages: {
    "*": ["common", "help/side-drawer/request-callback"],
    "/[...path]": contentPage,
    "/[productType]/[screeningId]/checkout/[stage]": [
      "air-con/checkout",
      "battery-only/checkout",
      "checkout",
      "heat-pump/checkout",
      "orders/checkout",
      "battery-only/savings",
    ],
    "/[productType]/guides/[slug]": guidePage,
    "/[productType]/inactive-region": [
      "checkout",
      "boilers/inactive-region",
      "ev-charger/inactive-region",
      "heat-pump/inactive-region",
      "solar/inactive-region",
    ],
    "/[productType]/your-options/[screeningId]": [
      "air-con/checkout",
      "air-con/options",
      "air-con/screener",
      "battery-only/checkout",
      "battery-only/detail",
      "battery-only/options",
      "battery-only/savings",
      "battery-only/screener",
      "boilers/options",
      "checkout",
      "ev-charger/options",
      "heat-pump/checkout",
      "heat-pump/options",
      "product-specs",
    ],
    "/[productType]/your-options/[screeningId]/[packageSlug]": [
      "air-con/checkout",
      "air-con/detail",
      "ev-charger/detail",
      "battery-only/detail",
      "battery-only/checkout",
      "battery-only/savings",
      "boilers/detail",
      "boilers/options",
      "boilers/subscription/options",
      "boilers/subscription/pdp",
      "checkout",
      "heat-pump/checkout",
      "heat-pump/detail",
      "heat-pump/steps",
      "products",
      "product-specs",
      "request-callback",
      "specs",
    ],
    "/[productType]/your-options/[screeningId]/add-ons": ["boilers/add-ons"],
    "/[productType]/your-options/[screeningId]/add-ons/controls": ["boilers/add-ons", "checkout", "boilers/options"],
    "/[productType]/your-options/[screeningId]/add-ons/extras": ["boilers/add-ons", "checkout", "boilers/options"],
    "/air-conditioning/callout": ["air-con/callout", "air-con/checkout"],
    "/air-conditioning/your-home": ["air-con/checkout", "air-con/screener", "boilers/screener"],
    "/boilers": ["boilers/index"],
    "/boilers/boiler-repair": ["repair/index"],
    "/boilers/boiler-repair/[jobId]/confirmation": ["repair/confirmation"],
    "/boilers/boiler-repair/checkout/[stage]": ["repair/index", "repair/checkout", "checkout"],
    "/boilers/boiler-repair/google": ["repair/index", "heatlink/index"],
    "/boilers/boiler-repair/google/[jobId]/confirmation": ["repair/google/confirmation"],
    "/boilers/boiler-repair/google/checkout/[stage]": [
      "repair/google/checkout",
      "repair/index",
      "repair/checkout",
      "checkout",
    ],
    "/boilers/boiler-repair/google/your-boiler": ["repair/screener"],
    "/boilers/boiler-repair/google/your-options": ["repair/index", "heatlink/options", "repair/index"],
    "/boilers/boiler-repair/your-boiler": ["repair/screener", "repair/index"],
    "/boilers/boiler-repair/your-options": ["repair/options", "repair/index"],
    "/boilers/callout": ["checkout", "boilers/callout"],
    "/boilers/callout/oil": ["checkout", "boilers/callout"],
    "/boilers/inactive-region": ["checkout", "boilers/inactive-region"],
    "/boilers/subscription": ["boilers/subscription/index", "checkout", "boilers/subscription/checkout"],
    "/boilers/subscription/[orderId]/confirmation": [
      "checkout",
      "boilers/subscription/confirmation",
      "boilers/subscription/checkout",
    ],
    "/boilers/subscription/additional-information": [
      "checkout",
      "boilers/subscription/checkout",
      "boilers/subscription/additional-information",
    ],
    "/boilers/subscription/additional-information-hs": [
      "checkout",
      "boilers/subscription/checkout",
      "boilers/subscription/additional-information-hs",
    ],
    "/boilers/subscription/callout": ["checkout", "boilers/subscription/checkout", "boilers/subscription/callout"],
    "/boilers/subscription/callout/oil": ["checkout", "boilers/subscription/checkout", "boilers/subscription/callout"],
    "/boilers/subscription/checkout-hs/[stage]": [
      "checkout",
      "boilers/subscription/checkout",
      "boilers/subscription/options",
    ],
    "/boilers/subscription/checkout/[stage]": [
      "checkout",
      "boilers/subscription/checkout",
      "boilers/subscription/options",
    ],
    "/boilers/subscription/declined": [
      "boilers/subscription/declined",
      "checkout",
      "boilers/subscription/checkout",
      "request-callback",
    ],
    "/boilers/subscription/declined-hs": [
      "boilers/subscription/declined",
      "checkout",
      "boilers/subscription/checkout",
      "request-callback",
    ],
    "/boilers/subscription/re-sign-agreement/[stage]": [
      "checkout",
      "boilers/subscription/checkout",
      "boilers/subscription/referred-checkout-hs",
      "boilers/subscription/options",
    ],
    "/boilers/subscription/referred-hs/checkout/[stage]": [
      "checkout",
      "boilers/subscription/checkout",
      "boilers/subscription/referred-checkout-hs",
      "boilers/subscription/options",
    ],
    "/boilers/subscription/referred/checkout-hs/[stage]": [
      "checkout",
      "boilers/subscription/checkout",
      "boilers/subscription/referred-checkout",
      "boilers/subscription/options",
    ],
    "/boilers/subscription/referred/checkout/[stage]": [
      "checkout",
      "boilers/subscription/checkout",
      "boilers/subscription/referred-checkout",
      "boilers/subscription/options",
    ],
    "/boilers/subscription/your-home": ["boilers/subscription/screener", "checkout", "boilers/subscription/checkout"],
    "/boilers/subscription/your-options": ["boilers/subscription/options", "checkout", "boilers/subscription/checkout"],
    "/boilers/subscription/your-options/[packageSlug]": [
      "boilers/subscription/pdp",
      "boilers/subscription/options",
      "boilers/detail",
      "product-specs",
      "request-callback",
    ],
    "/boilers/your-home": ["boilers/screener", "checkout"],
    "/cover/callout/landlord": ["cover/callout"],
    "/cover/your-home": ["cover/screener"],
    "/cover/your-options": ["cover/your-options", "home-cover/index"],
    "/ev-chargers/unable-to-install": ["ev-charger/unable-to-install"],
    "/ev-chargers/your-home": ["ev-charger/screener", "ev-charger/checkout"],
    "/ev-chargers/your-options": ["ev-charger/checkout", "ev-charger/options"],
    "/finance": ["finance/index"],
    "/guides": ["guides/index"],
    "/heat-pumps/inactive-region": ["heat-pump/inactive-region"],
    "/heat-pumps/unable-to-install/flat-on-elevated-floor": [
      "heat-pump/screener",
      "heat-pump/checkout",
      "heat-pump/unable-to-install",
    ],
    "/heat-pumps/unable-to-install/no-permission-for-flat": [
      "heat-pump/screener",
      "heat-pump/checkout",
      "heat-pump/unable-to-install",
    ],
    "/heat-pumps/unable-to-install/no-room-for-cylinder": [
      "heat-pump/screener",
      "heat-pump/checkout",
      "heat-pump/unable-to-install",
    ],
    "/heat-pumps/unable-to-install/no-space-for-outdoor-unit": [
      "heat-pump/screener",
      "heat-pump/checkout",
      "heat-pump/unable-to-install",
    ],
    "/heat-pumps/unable-to-install/no-valid-epc": [
      "heat-pump/screener",
      "heat-pump/checkout",
      "heat-pump/unable-to-install",
    ],
    "/heat-pumps/your-home": ["heat-pump/screener", "heat-pump/checkout"],
    "/home-battery-storage/no-space": ["battery-only/screener", "battery-only/checkout"],
    "/home-battery-storage/your-home": ["battery-only/screener", "map", "battery-only/checkout"],
    "/home-cover": ["home-cover/index", "home-cover/report-an-issue"],
    "/home-cover/[subscriptionId]/confirmation": ["home-cover/confirmation", "home-cover/checkout"],
    "/home-cover/cannot-cover/fuel": ["home-cover/cannot-cover-fuel", "home-cover/index"],
    "/home-cover/cannot-cover/options": ["home-cover/cannot-cover-options", "home-cover/index"],
    "/home-cover/checkout/[stage]": ["home-cover/checkout", "home-cover/index", "checkout"],
    "/home-cover/your-home": ["home-cover/screener"],
    "/home-cover/your-options": ["home-cover/options", "home-cover/index"],
    "/list": ["list/index"],
    "/order-management/[orderId]/[paymentStep]": [
      "order-management/help-drawer-faqs",
      "order-management/payment",
      "orders/checkout",
      "checkout",
    ],
    "/order-management/[orderId]/[paymentStep]/confirmation": [
      "battery-only/checkout",
      "heat-pump/checkout",
      "orders/checkout",
      "order-management/help-drawer-faqs",
      "order-management/payment-confirmation",
      "solar/checkout",
      "solar/options",
    ],
    "/order-management/[orderId]/sat-note": ["order-management/help-drawer-faqs", "order-management/sat-note"],
    "/order-management/[orderId]/sat-note/thank-you": [
      "order-management/help-drawer-faqs",
      "order-management/sat-note-thank-you",
    ],
    "/order-management/expired-link": [
      "order-management/expired-link",
      "order-management/help-drawer-faqs",
      "request-callback",
    ],
    "/orders/[orderId]/additional-payments": ["orders/additional-payments", "orders/checkout", "checkout"],
    "/orders/[orderId]/confirmation": [
      "orders/confirmation",
      "orders/checkout",
      "air-con/confirmation",
      "ev-charger/confirmation",
      "heat-pump/confirmation/basket-summary",
      "heat-pump/confirmation/confirmation",
      "heat-pump/confirmation/upload-photos",
      "battery-only/confirmation/confirmation",
      "battery-only/confirmation/upload-photos",
      "battery-only/confirmation/basket-summary",
    ],
    "/orders/[orderId]/finance-application": ["orders/finance-application"],
    "/photos/[flow]/[id]/upload-photos": [
      "photos/orders/upload-photos",
      "photos/orders/photos",
      "photos/orders/heat-pumps/photos",
      "photos/orders/heat-pumps/upload-photos",
      "photos/rental/upload-photos",
      "photos/rental/photos",
      "photos/repairs/photos",
      "photos/repairs/upload-photos",
      "photos/homecover/photos",
      "photos/homecover/upload-photos",
      "photos/callouts/photos",
      "photos/callouts/upload-photos",
      "photos/solar/photos",
      "photos/solar/upload-photos",
    ],
    "/photos/[flow]/[id]/upload-photos/[photo]": [
      "photos/orders/photos",
      "photos/rental/photos",
      "photos/repairs/photos",
      "photos/homecover/photos",
      "photos/orders/upload-photos",
      "photos/orders/heat-pumps/photos",
      "photos/orders/heat-pumps/upload-photos",
      "photos/repairs/upload-photos",
      "photos/homecover/upload-photos",
      "photos/rental/upload-photos",
      "photos/callouts/photos",
      "photos/callouts/upload-photos",
      "photos/solar/photos",
      "photos/solar/upload-photos",
    ],
    "/photos/[flow]/[id]/upload-photos/completed": [
      "photos/orders/completed",
      "photos/rental/completed",
      "photos/repairs/completed",
      "photos/solar/completed",
      "photos/solar/upload-photos",
      "photos/homecover/completed",
      "photos/callouts/completed",
      "photos/callouts/completed-book",
      "photos/callouts/completed-view",
      "photos/repairs/upload-photos",
      "photos/homecover/upload-photos",
      "photos/orders/upload-photos",
      "photos/rental/upload-photos",
      "photos/callouts/upload-photos",
    ],
    "/photos/[flow]/[id]/upload-photos/no-longer-required": [
      "photos/orders/no-longer-required",
      "photos/rental/no-longer-required",
      "photos/repairs/no-longer-required",
      "photos/homecover/no-longer-required",
      "photos/repairs/upload-photos",
      "photos/homecover/upload-photos",
      "photos/orders/upload-photos",
      "photos/rental/upload-photos",
    ],
    "/preview/[...path]": contentPage,
    "/preview/[productType]/guides/[slug]": guidePage,
    "/product-recall/[...path]": ["product-recall/index"],
    "/report-issue": ["report-issue/home", "repair/index"],
    "/report-issue/[visitId]/booking": ["report-issue/booking"],
    "/report-issue/[visitId]/confirmation": ["report-issue/confirmation"],
    "/report-issue/guarantee-expired": [
      "repair/index",
      "repair/options",
      "report-issue/home",
      "report-issue/kickout-options",
    ],
    "/report-issue/issue-already-received": ["report-issue/issue-already-received", "report-issue/home"],
    "/report-issue/issue-type": [
      "report-issue/screener",
      "report-issue/co-alarm",
      "report-issue/gas-leak",
      "report-issue/issue-overview",
    ],
    "/report-issue/review/[entityId]": ["report-issue/review"],
    "/report-issue/service-only": [
      "repair/index",
      "report-issue/home",
      "repair/options",
      "report-issue/kickout-options",
    ],
    "/report-issue/solar": ["report-issue/solar", "report-issue/home"],
    "/report-issue/warranty-expired": [
      "repair/index",
      "repair/options",
      "report-issue/home",
      "report-issue/kickout-options",
    ],
    "/sat-note/[orderId]/complete": ["sat-note/complete"],
    "/sat-note/[orderId]/confirmed": ["sat-note/confirmed"],
    "/screener-scan": [
      "battery-only/screener",
      "boilers/screener",
      "heat-pump/screener",
      "repair/screener",
      "solar/screener",
      "solar/checkout",
      "ev-charger/screener",
      "checkout",
      "map",
    ],
    "/services/[serviceJobId]/book": ["services/book", "checkout"],
    "/services/[serviceJobId]/confirmation": ["services/confirmation"],
    "/solar/[proposalId]/confirmation": ["solar/checkout", "checkout", "solar/confirmation", "solar/steps"],
    "/solar/configure/[stage]": [
      "checkout",
      "products",
      "product-specs",
      "solar/checkout",
      "solar/options",
      "solar/quotes",
      "solar/savings",
      "solar/screener",
      "solar/steps",
      "specs",
    ],
    "/solar/enquiry/[screeningId]/[stage]": [
      "checkout",
      "solar/checkout",
      "solar/options",
      "solar/quotes",
      "solar/savings",
    ],
    "/solar/inactive-region": ["solar/inactive-region"],
    "/solar/unable-to-install": [
      "solar/unable-to-install",
      "solar/unable-to-install-renting",
      "solar/unable-to-install-flat",
      "solar/unable-to-install-flat-roof",
      "solar/checkout",
      "checkout",
    ],
    "/solar/your-home": ["solar/screener", "solar/checkout", "checkout", "map"],
    "/solar/your-home-redux": ["solar/screener", "solar/checkout", "checkout", "map"],
  },
};
