import ReactGA from "react-ga";

import { googleAnalyticsID } from "./constants";

let isGaInitialized = false;

const getAnalyticsApi = () => {
  if (!isGaInitialized) {
    ReactGA.initialize(googleAnalyticsID);
    isGaInitialized = true;
  }
  return {
    ReactGA,
    ReactGAEvent: ReactGA.event,
  };
};

export default getAnalyticsApi();
