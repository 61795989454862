import { http, HttpResponse } from "msw";

import mockRentalInitialStateValues from "@Mocks/mockRentalInitialStateValues";

import baseURL from "./constants";

export const handlers = [
  http.get(`${baseURL}/orders/subscription/checkout`, () =>
    HttpResponse.json({
      stage: "your-details",
      values: mockRentalInitialStateValues,
    }),
  ),
  http.get(`https://vimeo.com/*`, () => HttpResponse.json({})),
  http.get(`https://api.buttercms.com/*`, () => HttpResponse.json({})),
  http.post(`${baseURL}/payments/stripe/upfront-payment-intent`, () =>
    HttpResponse.json({
      clientSecret: "pi_3Q4ekKHdMR0qaxX01HAh1jGH_secret_YXrgQ0CnvmchARicNK8pHV36z",
      id: "pi_3Q4ekKHdMR0qaxX01HAh1jGH_secret_YXrgQ0CnvmchARicNK8pHV36z",
    }),
  ),
  http.post(`${baseURL}/payments/stripe/partial-payment-intent`, () =>
    HttpResponse.json({
      clientSecret: "pi_3Q4ekKHdMR0qaxX01HAh1jGH_secret_YXrgQ0CnvmchARicNK8pHV36z",
      id: "pi_3Q4ekKHdMR0qaxX01HAh1jGH_secret_YXrgQ0CnvmchARicNK8pHV36z",
    }),
  ),
  http.post(`${baseURL}/payments/stripe/additional-payment-intent`, () =>
    HttpResponse.json({
      clientSecret: "pi_3Q4ekKHdMR0qaxX01HAh1jGH_secret_YXrgQ0CnvmchARicNK8pHV36z",
      id: "pi_3Q4ekKHdMR0qaxX01HAh1jGH_secret_YXrgQ0CnvmchARicNK8pHV36z",
    }),
  ),
  http.get(`${baseURL}/addresses`, () =>
    HttpResponse.json([
      {
        addressLine1: "line1,",
        addressLine2: "line2",
        addressLine3: null,
        buildingNumber: null,
        city: "city",
        county: null,
        displayableAddress: "1 Cowper Road, London, SW19 1AA",
        latitude: "51.421628472916645",
        longitude: "-0.18613690208333325",
        postcode: "TR13 1AA",
      },
    ]),
  ),
];
