import type { MutableRefObject } from "react";
import { useEffect, useRef } from "react";
import getConfig from "next/config";
import type AnalyticsJS from "@segment/analytics.js-core";

export const useAnalytics = () => {
  const segment: MutableRefObject<typeof AnalyticsJS | null> = useRef(null);
  const { publicRuntimeConfig } = getConfig();

  useEffect(() => {
    if (window !== undefined && segment.current === null) {
      segment.current = window.analytics;
      if (publicRuntimeConfig?.ENVIRONMENT !== "production") {
        // Each of these new users costs us £££ so to get round this we need to make segment think that
        // all our Dev & QA users are all the same person
        window.analytics.setAnonymousId("868b6bb2-49f2-4389-8a84-26f0fac8ef79");
      }
    }
  }, [segment, publicRuntimeConfig]);

  return segment.current;
};
