import type { SubscriptionCheckoutValues } from "@Components/Checkout/stages/types";

const mockRentalInitialStateValues: SubscriptionCheckoutValues = {
  acceptTerms: false,
  addressHistory: [
    {
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      county: "",
      current: true,
      id: "",
      monthsAtAddress: "",
      postcode: "",
      yearsAtAddress: "",
    },
  ],
  checkoutInstallerId: "",
  dateOfBirth: "",
  email: "",
  financialInfo: {
    employmentStatus: "",
    financialSituationChanged: "",
    householdIncome: "",
    mortgagePayments: "",
    numberOfAdults: "",
    numberOfChildren: "",
    otherExpenses: "",
    takeHomePay: "",
  },
  firstName: "",
  installationAddress: {
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    county: "",
    note: "",
    postcode: "",
  },
  installationPrice: 0,
  jobLength: 0,
  oversellSlotAvailable: false,
  selectedDate: "",
  surname: "",
  telephone: "",
  title: "",
};

export const mockRentalInitialStateValuesWithAddressHistory: SubscriptionCheckoutValues = {
  acceptTerms: true,
  addressHistory: [
    {
      addressLine1: "11a Cowper Road",
      addressLine2: "",
      addressLine3: "",
      city: "London",
      county: "",
      current: true,
      id: "1234",
      monthsAtAddress: "",
      postcode: "sw19 1aa",
      yearsAtAddress: "",
    },
  ],
  checkoutInstallerId: null,
  dateOfBirth: "10/07/1986",
  email: "email@test.com",
  financialInfo: {
    employmentStatus: "",
    financialSituationChanged: "",
    householdIncome: "",
    mortgagePayments: "",
    numberOfAdults: "",
    numberOfChildren: "",
    otherExpenses: "",
    takeHomePay: "",
  },
  firstName: "firstname",
  installationAddress: {
    addressLine1: "11a Cowper Road",
    addressLine2: "",
    addressLine3: "",
    city: "London",
    county: "",
    note: "",
    postcode: "sw19 1aa",
  },
  installationPrice: 0,
  jobLength: 1,
  selectedDate: "2022-06-29",
  surname: "surname",
  telephone: "07812454246",
  title: "Mr",
};

export default mockRentalInitialStateValues;
