import type { FlagMetadata } from "@flagship.io/react-sdk";

type Props = {
  metaData: FlagMetadata;
  key: string;
  segmentAnalytics: any;
};

const onVisitorExposedHelper = ({ key, metaData, segmentAnalytics }: Props) => {
  const flagList = window.sessionStorage.getItem("flagList");

  if (!flagList?.split(",").includes(key) && segmentAnalytics) {
    const { campaignId, isReference, variationGroupId, variationId } = metaData;
    const joinedList = flagList ? flagList.concat(`,${key}`) : key;
    segmentAnalytics.track("flagship_test_seen", {
      campaignID: campaignId,
      campaignName: key,
      isReference,
      variationGroupID: variationGroupId,
      variationID: variationId,
    });
    window.sessionStorage.setItem("flagList", joinedList);
  }
};

export default onVisitorExposedHelper;
