
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React, { useCallback, useEffect, useRef, useState } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { Provider as ReduxProvider } from "react-redux";
import type { NextComponentType, NextPageContext } from "next";
import type { AppProps } from "next/app";
import App from "next/app";
import { useRouter } from "next/compat/router";
import getConfig from "next/config";
import dynamic from "next/dynamic";
import Head from "next/head";
import Script from "next/script";
import useTranslation from "next-translate/useTranslation";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import { ResizeObserver } from "@juggle/resize-observer";
import { store } from "@ReduxStore/store";
import * as snippet from "@segment/snippet";
import { CLIENT_CONFIG, Footer } from "@boxt/design-system";
import Bugsnag from "@Lib/bugsnag";
import { googleAnalyticsID, ReactGA } from "@Lib/googleAnalytics";
import { TagManager, tagManagerArgs } from "@Lib/googleTagManager";
import FlagshipWrapper from "@Hoc/FlagshipWrapper";
import SegmentAnalyticsWrapper from "@Hoc/SegmentAnalyticsWrapper";
import ErrorPage from "./_error";
import GlobalStyles from "./_styles";
import "@boxt/design-system/dist/styles.css";
import "@Styles/fonts.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-phone-number-input/style.css";
global.ResizeObserver = ResizeObserver;
import { FooterContainer } from "@Components/Footer/styles";
import type { IStep } from "@Components/Navigation/Navigation";
import { FooterGap } from "@Components/SolarConfigurator/BottomBar/styles";
import type { PageSteps } from "@Pages/api/_next/screeners/types"; // this polyfill requires resize-observer polyfill
import "intl-pluralrules";
const Navigation = dynamic(() => import("@Components/Navigation"), { ssr: false });
const ErrorBoundary: any = Bugsnag.getPlugin("react")?.createErrorBoundary(React);
const { publicRuntimeConfig } = getConfig();
if (publicRuntimeConfig.API_MOCKING === "enabled") {
    require("@Mocks/api");
}
const Chat = dynamic(() => import("@Lib/Chat"), { ssr: false });
type AppPropsWithLayout = AppProps & {
    Component: NextComponentType<NextPageContext, {
        hasFlagshipLoaded: boolean;
        onRequestCallBack: () => void;
    } & PageSteps<IStep>> & {
        layoutConfig?: {
            hasCustomFooter: boolean;
            hasCustomNavigation: boolean;
        };
    };
};
const pagesWithExtraHeightOnFooter = [
    "/[productType]/your-options/[screeningId]/[packageSlug]",
    "/[productType]/your-options/[screeningId]/add-ons/controls",
    "/[productType]/your-options/[screeningId]/add-ons/extras",
    "/become-a-boxt-installer",
    "/boilers/your-home",
    "/how-boxt-works",
    "/solar",
    "/solar/configure/[stage]",
    "/solar/enquiry/[stage]",
    "/who-we-are",
    "/your-installer-experience",
];
const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
    const { t } = useTranslation("common");
    const year = new Date().getFullYear();
    const router = useRouter();
    const [step, setStep] = useState(null);
    const [stepOnBack, setStepOnBack] = useState();
    const isRedirectedFromApp = router?.query["utm_medium"] === "app" && router?.pathname.includes("upload-photos");
    const showFooterExtraHeight = pagesWithExtraHeightOnFooter.includes(String(router?.pathname)) ||
        pagesWithExtraHeightOnFooter.includes(String(router?.asPath));
    // this function will be executed on route change in NavBar, and if
    // output is true, route will change
    const [shouldRouteChange, setShouldRouteChange] = useState<(to: string) => boolean>();
    const [isHelpDrawerOpen, setIsHelpDrawerOpen] = useState(false);
    const [isCallbackFormOpen, setIsCallbackFormOpen] = useState(false);
    const [hasFlagshipLoaded, setHasFlagshipLoaded] = useState(false);
    const isStaging = publicRuntimeConfig.ENVIRONMENT === "review" ||
        publicRuntimeConfig.ENVIRONMENT === "qa" ||
        publicRuntimeConfig.ENVIRONMENT === "uat";
    const adobeTagManagerSrc = `https://assets.adobedtm.com/${publicRuntimeConfig.ADOBE_TAG_MANAGER_ORG_KEY}/${publicRuntimeConfig.ADOBE_TAG_MANAGER_PRODUCT_KEY}/launch-${publicRuntimeConfig.ADOBE_TAG_MANAGER_STAGING_ENV_KEY}-staging.min.js`;
    const loadSegment = () => {
        const options = {
            apiKey: publicRuntimeConfig.SEGMENT_WRITE_KEY,
            page: false,
        };
        if (publicRuntimeConfig.SEGMENT_WRITE_KEY) {
            return snippet.max(options);
        }
        else {
            return snippet.min(options);
        }
    };
    const handleOnRequestCallBack = () => {
        setIsHelpDrawerOpen(true);
        setIsCallbackFormOpen(true);
    };
    // Changes to routing introduced in Next v12.2 has removed routeChangeComplete
    // events on initial page load (https://github.com/vercel/next.js/issues/11639#issuecomment-1219654377)
    // and so a new approach is required to track initial page views.
    const [lastTrackedPath, setLastTrackedPath] = useState<null | string>(null);
    const trackPageView = useCallback((path: string) => {
        // Avoid double tracking
        if (path !== lastTrackedPath) {
            ReactGA.pageview(path);
            setLastTrackedPath(path);
        }
    }, [lastTrackedPath]);
    useEffect(() => {
        trackPageView(String(router?.asPath));
    }, [lastTrackedPath, trackPageView, router?.asPath]);
    // Track initial page load
    useEffect(() => {
        if (router?.isReady) {
            setLastTrackedPath(router?.asPath);
        }
    }, [router?.isReady, router?.asPath, trackPageView]);
    // Track page routing
    useEffect(() => {
        const onRouteChangeComplete = (asPath: string) => {
            setLastTrackedPath(asPath);
        };
        router?.events.on("routeChangeComplete", onRouteChangeComplete);
        return () => {
            router?.events.off("routeChangeComplete", onRouteChangeComplete);
        };
    }, [router?.events, trackPageView]);
    useEffect(() => {
        const optimizeContainerId = publicRuntimeConfig.GOOGLE_OPTIMIZE_ID;
        if (window?.ga && optimizeContainerId) {
            window.ga("create", googleAnalyticsID, "auto");
            window.ga("require", optimizeContainerId);
            window.ga("send", "pageview");
        }
    }, []);
    useEffect(() => {
        TagManager.initialize(tagManagerArgs);
    }, []);
    const handleOnHelpDrawerClick = () => {
        setIsHelpDrawerOpen((prevState) => !prevState);
    };
    const footerRef = useRef<HTMLDivElement>(null);
    const footerDimensions = footerRef?.current?.getBoundingClientRect();
    // hide chat and footer on redirect from app
    const hasFooter = !Component.layoutConfig?.hasCustomFooter && !isRedirectedFromApp;
    const hasChat = hasFlagshipLoaded && !isRedirectedFromApp;
    const hasNavigation = !Component.layoutConfig?.hasCustomNavigation;
    const serviceClient = publicRuntimeConfig.SERVICE_CLIENT || "boxt";
    const theme = CLIENT_CONFIG[serviceClient].theme;
    return (<>
      {isStaging && <Script async id="adobe-dtm" src={adobeTagManagerSrc}/>}
      <Script id="ab-tasty" src={`https://try.abtasty.com/${publicRuntimeConfig.AB_TASTY_ID}.js`} strategy="afterInteractive" type="text/javascript"/>
      <Script 
    // eslint-disable-next-line jam3/no-sanitizer-with-danger
    dangerouslySetInnerHTML={{ __html: loadSegment() }} id="segmentScript"/>
      <Head>
        <meta content="LHYBS6rRIk4HqfYd1Ijg3ikB0KU20FeUybLbfPlyyno" name="google-site-verification"/>
        <link href="/_next-public/apple-touch-icon-114x114.png" rel="apple-touch-icon-precomposed" sizes="114x114"/>
        <link href="/_next-public/apple-touch-icon-72x72.png" rel="apple-touch-icon-precomposed" sizes="72x72"/>
        <link href="/_next-public/apple-touch-icon-144x144.png" rel="apple-touch-icon-precomposed" sizes="144x144"/>
        <link href="/_next-public/apple-touch-icon-60x60.png" rel="apple-touch-icon-precomposed" sizes="60x60"/>
        <link href="/_next-public/apple-touch-icon-120x120.png" rel="apple-touch-icon-precomposed" sizes="120x120"/>
        <link href="/_next-public/apple-touch-icon-76x76.png" rel="apple-touch-icon-precomposed" sizes="76x76"/>
        <link href="/_next-public/apple-touch-icon-152x152.png" rel="apple-touch-icon-precomposed" sizes="152x152"/>

        <link href={`/_next-public/favicon/${publicRuntimeConfig.ENVIRONMENT}/32x32.png`} rel="icon" sizes="32x32" type="image/png"/>
      </Head>
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <StyleSheetManager enableVendorPrefixes>
            <GlobalStyles />
            <SkeletonTheme baseColor="#172B4D05" highlightColor="#172B4D0F">
              <ErrorBoundary FallbackComponent={ErrorPage}>
                <SegmentAnalyticsWrapper path={router?.asPath}>
                  <FlagshipWrapper apiKey={publicRuntimeConfig.FLAGSHIP_API_KEY} enableClientCache envId={publicRuntimeConfig.FLAGSHIP_ENV_ID} setHasFlagshipLoaded={() => {
            setHasFlagshipLoaded(true);
        }}>
                    {hasNavigation && (<Navigation isCallbackFormOpen={isCallbackFormOpen} isHelpDrawerOpen={isHelpDrawerOpen} onBack={stepOnBack} onHelpDrawerClick={handleOnHelpDrawerClick} onSideDrawerToggle={setIsHelpDrawerOpen} setIsCallbackFormOpen={setIsCallbackFormOpen} shouldRouteChange={shouldRouteChange} step={step}/>)}
                    <Component {...pageProps} footerHeight={footerDimensions?.height} hasFlagshipLoaded={hasFlagshipLoaded} isHelpDrawerOpen={isHelpDrawerOpen} onRequestCallBack={handleOnRequestCallBack} setShouldRouteChange={setShouldRouteChange} setStep={setStep} setStepOnBack={setStepOnBack}/>

                    {hasFooter && (<FooterContainer id="footer" ref={footerRef}>
                        <Footer copyText={t("footer.copy-text", { year })} homePageUrl="/" legalText={t("footer.legal-text")}/>
                        {showFooterExtraHeight && <FooterGap />}
                      </FooterContainer>)}
                    {hasChat && <Chat />}
                  </FlagshipWrapper>
                </SegmentAnalyticsWrapper>
              </ErrorBoundary>
            </SkeletonTheme>
          </StyleSheetManager>
        </ThemeProvider>
      </ReduxProvider>
    </>);
};
MyApp.getInitialProps = async (appContext) => {
    const appProps = await App.getInitialProps(appContext);
    return { ...appProps };
};
const __Next_Translate__Page__1932af7a715__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__1932af7a715__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  