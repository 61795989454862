"use client";

import styled, { css } from "styled-components";
import tinyColor from "tinycolor2";
import { breakpoint, Button, Caption, colors, includeSpacing, rem } from "@boxt/design-system";

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: ${colors.white};
  z-index: 10;
  box-shadow: 0px 0px 15px 0px #33455726;
`;

export const FooterGap = styled.div`
  height: ${rem(100)};
  width: 100%;
`;

export const IntercomContainer = styled.div`
  ${breakpoint("md")} {
    width: ${rem(48)};
    padding: 0 ${rem(34)};
  }
  ${breakpoint("lg")} {
    display: none;
  }
`;

export const CostColumn = styled.div`
  padding: 0;
  display: flex;
  justify-content: flex-end;
  ${breakpoint("lg")} {
    padding: 0 ${rem(16)};
  }
`;

const getCostContainerGridMobile = ({ isSolarFinanceAvailable, isSolarQuoteEnabled }) => {
  if (!isSolarFinanceAvailable && isSolarQuoteEnabled) {
    return "max-content max-content min-content";
  }

  return isSolarFinanceAvailable
    ? "minmax(115px, 1fr) minmax(85px, 1fr) min-content"
    : "minmax(75px, 1fr) minmax(135px, 1fr) min-content";
};

export const CostContainer = styled.div<{ $isSolarFinanceAvailable: boolean; $isSolarQuoteEnabled: boolean }>`
  display: grid;
  grid-template-columns: ${({ $isSolarFinanceAvailable, $isSolarQuoteEnabled }) =>
    getCostContainerGridMobile({
      isSolarFinanceAvailable: $isSolarFinanceAvailable,
      isSolarQuoteEnabled: $isSolarQuoteEnabled,
    })};
  height: 100%;
  width: 100%;
  justify-content: flex-end;

  ${includeSpacing({ mr: { lg: 5, sm: 0 } })}

  ${breakpoint("md")} {
    display: flex;
    flex-direction: row;
  }
`;

const PaymentButtonStyles = css`
  border: 0;
  border-left: 1px solid ${colors.platinum.light};
  background: none;
  text-align: left;
  cursor: pointer;
  flex: 1 1 auto;
  align-items: flex-start;
  flex-direction: column;

  ${breakpoint("md")} {
    flex: initial;
  }

  &:hover {
    background: ${colors.slate.lightest};
  }
`;

export const FullSystemPriceButton = styled.button`
  ${PaymentButtonStyles}
  display: flex;
  padding: ${rem(12)} ${rem(8)} ${rem(8)} ${rem(16)};

  ${breakpoint("md")} {
    padding: ${rem(12)} ${rem(24)};
  }

  &:hover {
    background: ${colors.slate.lightest};
  }
`;

export const PayMonthlyButton = styled.button`
  ${PaymentButtonStyles}

  display: flex;
  padding: ${rem(12)} ${rem(8)} ${rem(8)} ${rem(16)};

  ${breakpoint("md")} {
    padding: ${rem(12)} ${rem(24)};
  }

  &:hover {
    background: ${colors.slate.lightest};
  }
`;

export const CostButtonCopyStyle = styled.span`
  display: block;
`;

export const MonthlySavingsButton = styled.button<{
  $isSolarFinanceAvailable: boolean;
  $isSolarQuoteEnabled: boolean;
}>`
  border: 0;
  padding: ${rem(12)} ${rem(8)} ${rem(8)} ${rem(16)};
  background: ${tinyColor(colors.jade.lighter).setAlpha(0.2).toRgbString()};
  text-align: left;
  flex: 1 1 20%;
  display: none;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;

  &:hover {
    background: ${tinyColor(colors.jade.lighter).setAlpha(0.3).toRgbString()};
  }

  ${breakpoint("md")} {
    display: flex;
    flex: initial;
    padding: ${rem(12)} ${rem(24)};
  }
`;

export const MonthlySavingsDesktop = styled(Caption)`
  display: none;

  ${breakpoint("md")} {
    display: block;
  }
`;

export const MonthlySavingsMobile = styled(Caption)`
  ${breakpoint("md")} {
    display: none;
  }
`;

export const NextButtonContainer = styled.div<{ $showLeftBorder: boolean }>`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  ${breakpoint("md")} {
    ${({ $showLeftBorder }) =>
      $showLeftBorder &&
      css`
        border-left: 1px solid ${colors.platinum.light};
      `};
  }
`;

export const InlineSpan = styled.span`
  display: inline-block;
`;

export const MonthlySavingsCopy = styled.span`
  display: flex;
`;

export const GraphIcon = styled.span`
  display: none;

  ${breakpoint("md")} {
    display: block;
    width: ${rem(20)};
    height: ${rem(41)};
    margin-right: ${rem(16)};
  }
`;

export const TotalPriceContainer = styled.span`
  display: flex;
  flex-direction: row;
  gap: ${rem(8)};
  align-items: flex-end;
`;

export const SkeletonContainer = styled.span`
  display: block;
  flex: 1 0 auto;
  font-size: 0;
`;

export const OriginalPrice = styled.span`
  text-decoration: line-through;
`;

export const BogofOfferTagContainer = styled.span`
  display: block;
  margin-top: ${rem(8)};
`;

export const CostAndOfferContainer = styled.span<{ $isBogofOfferEnabled: boolean }>`
  display: block;
  height: ${({ $isBogofOfferEnabled }) => ($isBogofOfferEnabled ? rem(63) : "auto")};
`;

export const MobileSaveQuoteButton = styled(Button)`
  padding: 0 ${rem(9)};
`;

export const MobileSaveQuoteButtonWrapper = styled.div`
  border-left: 1px solid ${colors.platinum.light};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 0 0 ${rem(8)};
  ${breakpoint("lg")} {
    display: none;
  }
`;

export const DesktopSaveQuoteButtonWrapper = styled.div`
  display: none;

  ${breakpoint("lg")} {
    align-items: center;
    border-left: 1px solid ${colors.platinum.light};
    display: flex;
    justify-content: center;
    width: max-content;
  }
`;
